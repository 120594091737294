<template>
  <div class="colllection-info-wrap">
    <h2 class="sub-title">收款信息</h2>
    <div :gutter="24" style="margin-top: 16px" class="colllectionInfoList">
        <!-- 选择新商品的价格 -->
        <div class="item">
            <v-text-field
              hide-details="auto"
              label="商品价格(元)"
              disabled
              outlined
              :dense="true"
              :height="42"
              :value="collectionInfo.newGoodsPrice"
            ></v-text-field>
        </div>
        <div class="item">
            <v-autocomplete
              v-model="discountT"
              :items="discountInfoList"
              :menu-props="{ bottom: true, offsetY: true }"
              :label="'商品优惠'"
              outlined
              item-text="activityName"
              item-value="id"
              :dense="true"
              :height="42"
              :clearable="true"
              clear-icon="$clear"
              hide-details="auto"
              @change="selectDiscountInfo"
              no-data-text="暂无数据"
            ></v-autocomplete>
            <div v-if="wxTradeOrderDiscountVOList.length" :span="24"><div style="height:24px;fontSize:14px;color:#f00">{{discountTip}}</div></div>
        </div>
        <div class="item">
            <v-text-field
               :value="price"
              label="商品优惠金额"
              :disabled="!wxTradeOrderDiscountVOList.length"
              hide-details="auto"
              outlined
              :dense="true"
              :height="42"
              @input="inputDiscountPrice"
            >
            </v-text-field>
            <div v-if="discountNotuse.length" @click="$parent.disountsShow = true" style="line-height: 32px; fontsize: 14px; color: #f00;cursor: pointer;">
              <i class="el-icon-warning-outline"></i>不可用优惠
            </div>
        </div>
        <!--原商品已收金额-->
        <div class="item">
          <v-text-field
            hide-details="auto"
            label="升班优惠(元)"
            disabled
            outlined
            :dense="true"
            :height="42"
            :value="collectionInfo.oldGoodsPrice"></v-text-field>
        </div>
        <div class="item">
            <div v-if="
                  collectionInfo.newGoodsPrice &&
                  collectionInfo.oldGoodsPrice
                ">
              <v-text-field
                :value="shouldPay"
                label="应收金额(元)"
                hide-details="auto"
                outlined
                :dense="true"
                :height="42"
                disabled
              ></v-text-field>
            </div>
        </div>
    </div>
      <div class="receiveItem">
        <h2 class="sub-title" style="margin-bottom: 10px">收款项目</h2>
        <section class="colllectionInfoBox">
          <receiveItem
            :columns="receiveItemColumns"
            :tableData="receiveItemData"
            :showSummary="true"
            :summaryMethod="receiveItemSummaryMethod"
          />
        </section>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    selectDiscountInfo:Function,
    inputDiscountPrice:Function,
    wxTradeOrderDiscountVOList:Array,
    discountPrice: [Number,String],
    discountTip: String,
    discountType:String,
    discountInfoList: Array,
    discountNotuse: Array,
    receiveItemData: Array,
    collectionInfo: Object,
    goodsDis: [Number, String],
    shouldPay: [Number, String],
  },
  components: {
    receiveItem: ()=> import('@/views/order/components/receive-item-table.vue')
  },
  watch: {
    discountPrice(newValue) {
        this.price = newValue;
    },
    discountType(newValue){
      this.discountT = newValue
    }
  },
  data(){
    return {
      discountT:this.discountType,
      price: this.discountPrice,
      receiveItemColumns: [
        { label: "收支项目名称", prop: "inoutProjectName"},
        { label: "标准价格", prop: "standardPrice", number: true },
        { label: "优惠额", prop: "goodsDiscount", number: true },
        { label: "应收金额", prop: "receivable", number: true },
      ],
    }
  },
  methods: {
    receiveItemSummaryMethod(param){
      const { columns=[], data=[] } = param;
      const sums = [];

      if( !columns.length || !data.length ){
        return sums;
      }
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }

        //表示只计算最后一项。
        if(index < columns.length - 1) return ''
        const values = data.map(item => Number(item.receivable));
        let totalPrice = values.reduce((prev, curr) => {
          return prev + (Number(curr) || 0)
        });
        sums[index] = totalPrice.toFixed(2)

      })  
      return sums;  
    },
  }
}
</script>

<style lang="scss" scoped>
.colllection-info-wrap {
  background: #fff;
  width: 94%;
  margin: 0 auto;

  .colllectionInfoBox {
    .positionLeft {
      margin-bottom: 12px;
    }

    .student-surplus {
      margin-left: 18px;
    }
  }

  .line1 {
    margin-bottom: 12px;

    span {
      margin-left: 8px;
      font-size: 12px;
      color: #333333;
    }
  }

  .label {
    color: #f54955;
    font-weight: 700;
    margin-right: 10px;
  }
}
.colllectionInfoList{
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  .item{
    width: 282px;
    margin-right: 24px;
    margin-bottom: 12px;
  }
}
.sub-title {
  color: #666;
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
}
</style>