var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "colllection-info-wrap" }, [
    _c("h2", { staticClass: "sub-title" }, [_vm._v("收款信息")]),
    _c(
      "div",
      {
        staticClass: "colllectionInfoList",
        staticStyle: { "margin-top": "16px" },
        attrs: { gutter: 24 }
      },
      [
        _c(
          "div",
          { staticClass: "item" },
          [
            _c("v-text-field", {
              attrs: {
                "hide-details": "auto",
                label: "商品价格(元)",
                disabled: "",
                outlined: "",
                dense: true,
                height: 42,
                value: _vm.collectionInfo.newGoodsPrice
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "item" },
          [
            _c("v-autocomplete", {
              attrs: {
                items: _vm.discountInfoList,
                "menu-props": { bottom: true, offsetY: true },
                label: "商品优惠",
                outlined: "",
                "item-text": "activityName",
                "item-value": "id",
                dense: true,
                height: 42,
                clearable: true,
                "clear-icon": "$clear",
                "hide-details": "auto",
                "no-data-text": "暂无数据"
              },
              on: { change: _vm.selectDiscountInfo },
              model: {
                value: _vm.discountT,
                callback: function($$v) {
                  _vm.discountT = $$v
                },
                expression: "discountT"
              }
            }),
            _vm.wxTradeOrderDiscountVOList.length
              ? _c("div", { attrs: { span: 24 } }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        height: "24px",
                        fontSize: "14px",
                        color: "#f00"
                      }
                    },
                    [_vm._v(_vm._s(_vm.discountTip))]
                  )
                ])
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "item" },
          [
            _c("v-text-field", {
              attrs: {
                value: _vm.price,
                label: "商品优惠金额",
                disabled: !_vm.wxTradeOrderDiscountVOList.length,
                "hide-details": "auto",
                outlined: "",
                dense: true,
                height: 42
              },
              on: { input: _vm.inputDiscountPrice }
            }),
            _vm.discountNotuse.length
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "line-height": "32px",
                      fontsize: "14px",
                      color: "#f00",
                      cursor: "pointer"
                    },
                    on: {
                      click: function($event) {
                        _vm.$parent.disountsShow = true
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning-outline" }),
                    _vm._v("不可用优惠 ")
                  ]
                )
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "item" },
          [
            _c("v-text-field", {
              attrs: {
                "hide-details": "auto",
                label: "升班优惠(元)",
                disabled: "",
                outlined: "",
                dense: true,
                height: 42,
                value: _vm.collectionInfo.oldGoodsPrice
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "item" }, [
          _vm.collectionInfo.newGoodsPrice && _vm.collectionInfo.oldGoodsPrice
            ? _c(
                "div",
                [
                  _c("v-text-field", {
                    attrs: {
                      value: _vm.shouldPay,
                      label: "应收金额(元)",
                      "hide-details": "auto",
                      outlined: "",
                      dense: true,
                      height: 42,
                      disabled: ""
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ])
      ]
    ),
    _c("div", { staticClass: "receiveItem" }, [
      _c(
        "h2",
        { staticClass: "sub-title", staticStyle: { "margin-bottom": "10px" } },
        [_vm._v("收款项目")]
      ),
      _c(
        "section",
        { staticClass: "colllectionInfoBox" },
        [
          _c("receiveItem", {
            attrs: {
              columns: _vm.receiveItemColumns,
              tableData: _vm.receiveItemData,
              showSummary: true,
              summaryMethod: _vm.receiveItemSummaryMethod
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }